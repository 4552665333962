.subtext-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: center;
}

.contact-form-error {
    color: #f9004d;
    display: inline-block;
    margin-left: .5rem;
    margin-bottom: .5rem;
    font-size: 1rem;
}

/********************************
        Line height
********************************/
.ln-1 {
    line-height: 1.1 !important;
}